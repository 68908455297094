<template>
    <div>
        <Loader v-if="false"/>
        <st-modal
            ref='modal'
            hide-header-delimiter
            no-close-on-backdrop
            no-close-on-esc
            hide-footer
            hideable
            size="md"
            :title="$t('APPLICATION.CREATE_PAYMENT_MODAL.TITLE')"
            customClass="form-modal"
            :id="modalId"
        >
            <template #body>
                <application-create-payment-form @submit="submit" />
            </template>
        </st-modal>
    </div>
</template>

<script>
import ApplicationCreatePaymentForm from './ApplicationCreatePaymentForm.vue';
import { generateRandomId } from '@/core/helpers/globalMethods';

export default {
    name: 'ApplicationCreatePaymentModal',
    components: {
        ApplicationCreatePaymentForm
    },
    data() {
        return {
            modalId: `st-modal-create-payment-${generateRandomId()}`,
        }
    },
    methods: {
        show() {
            this.$refs['modal'].show();
        },
        hide() {
            this.$refs['modal'].hide();
        },
        submit() {
            this.hide();
            this.$emit('submit');
        },
    }
}
</script>
