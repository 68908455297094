<template>
    <div>
        <form
            class="form"
            novalidate="novalidate"
            id="st-application-create-payment-form"
        >
            <div class="form-group">
                <label>
                    {{ fields.amount.label }} *
                </label>
                <b-form-input 
                    :name="fields.amount.name"
                    v-model="model[fields.amount.name]"
                    v-positiveDecimalOnly 
                />
            </div>
            <div class="form-group">
            <label>
                {{ fields.email.label }} *
            </label>
            <div>
                <st-input-text
                :ref="fields.email.name"
                :name="fields.email.name"
                v-model="model[fields.email.name]"
                />
            </div>
            </div>
        </form>
        <st-button
            size="medium"
            block
            variant="primary"
            :callback="createPaymentLink"
            :disabled="disabled"
            :spinner="pending"
        >
            {{ $t('APPLICATION.CREATE_PAYMENT_MODAL.BUTTON') }}
        </st-button>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { generateRandomId } from '@/core/helpers/globalMethods';
import { PaymentDetailsModel } from '@/modules/applications/models/payment-details-model';
import { createFormValidation } from "@/shared/utils/create-form-validation";

const { fields } = PaymentDetailsModel;

const formSchema = new FormSchema([
    fields.amount,
    fields.email,
])

export default {
    name: 'ApplicationCreatePaymentModal',
    data() {
        return {
            modalId: `st-modal-create-payment-${generateRandomId()}`,
            model: null,
            rules: formSchema.rules(),
            fields,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
            record: 'applications/form/record',
        }),
        disabled() {
            return !this.model.amount || this.model.amount <= 0 || this.pending;
        },
        pending() {
            return this.loading['applications/createPaymentLink'];
        },
    },
    methods: {
        ...mapActions({
            doCreatePaymentLink: "applications/paymentsForm/createPaymentLink",
            doFetch: "applications/paymentsList/doFetch",
        }),
        async createPaymentLink() {
            const validate = await this.fv.validate();
            if (validate === "Valid") {
                const cast = formSchema.cast(this.model);
                await this.doCreatePaymentLink({
                    id: this.record.id,
                    amount: cast.amount,
                    email: cast.email,
                });
                this.$emit('submit')
            }
        },
    },
    created() {
        this.model = formSchema.initialValues({ amount: 0, email: this.record.applicant_user.email })
    },
    mounted() {
        this.fv = createFormValidation("st-application-create-payment-form", this.rules);
    },
}
</script>
